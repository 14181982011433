import React from "react";
import { Col, Row } from "antd";
import Integrity from "assets/svgs/core-values/integrity.svg";
import Integration from "assets/svgs/core-values/integration.svg";
import Innovation from "assets/svgs/core-values/innovation.svg";

const CoreValues = () => {
  return (
    <Row className="core_values">
      <Col sm={24} xl={24}>
        <Row justify={"center"} align={"middle"}>
          <Col sm={24} xl={16}>
            <h1 className="title">
              Our Core <span>Values</span>
            </h1>
            <p className="desc">
              Our core values are the pillars that define our identity and guide
              our actions. These values underpin ZenIntel commitment to
              excellence, client success, and industry leadership.
            </p>
          </Col>
          <Col span={24} className="feature_container">
            <img src={Integrity} alt="Integrity" />
            <img src={Innovation} alt="Innovation" />
            <img src={Integration} alt="Integration" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CoreValues;

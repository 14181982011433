import React from "react";
import { Row, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Triangles from "../../assets/svgs/Triangles.svg";
import DesktopFooter from "../../assets/svgs/DesktopFooter.svg";
import Button from "components/button";
import "./footer.scss";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Col span={24} className="footer">
      <Row justify={"center"}>
        <Col sm={22} xl={20}>
          <Row>
            <Col xl={16} sm={24} className="left_area">
              <h1>
                <span className="blue_text">Let’s start</span> growing your
                business{" "}
              </h1>
              <span className="desc">
                At ZenIntel, we redefine the bounds of digital potential,
                providing a diverse range of services. Begin your path to
                technological excellence with us.
              </span>
              <Button
                className="button_style"
                onClick={() => navigate("/contact-us")}
              >
                Connect with us
              </Button>
            </Col>
            <Col span={8} className="right_area">
              <img src={Triangles} alt="footer" />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="bottom_area">
              <Row>
                <Col xl={12} sm={24} className="left_area">
                  {/* <span className="name">Upwyse</span> */}
                  {/* <img src={DesktopFooter} alt={"DeskTopNav"} /> */}
                  <p>ZENINTEL</p>
                  <a
                    href="mailto:zenintel2024@gmail.com"
                    class="info-email"
                    target="_blank"
                    rel="noreferrer"
                  >
                    sales@zen-intel.com
                  </a>
                  <a
                    href="https://wa.me/+917401504726"
                    class="info-email"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +917401504726
                  </a>
                  <a
                    href="https://www.linkedin.com/company/zen-intel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin icon" />
                  </a>
                </Col>
                <Col xl={12} sm={24} className="right_area">
                  <ul className="links">
                    <li>
                      <Link>About us</Link>
                    </li>
                    <li>
                      <Link>Services</Link>
                    </li>
                    <li>
                      <Link>Blogs</Link>
                    </li>
                    <li>
                      <Link>Contact us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                  <span>Copyright © 2023 ZenIntel, All rights reserved.</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default Footer;

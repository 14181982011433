import React from "react";
import { Col, Row } from "antd";
import FixedBid from "../../assets/svgs/engagement-model/fixedbid.svg";
import Retainer from "../../assets/svgs/engagement-model/retainer.svg";
import Time from "../../assets/svgs/engagement-model/time.svg";

const MODELS = [
  {
    name: "Fixed Bid  Model",
    logo: FixedBid,
    desc: "The Fixed Bid Model offers predictable costs, simplifying budgeting and reducing risks, boosting confidence in project delivery. With this approach, offshore firms prioritize speed, efficacy, and top-tier product outcomes while assuming project risks.",
  },
  {
    name: "Time and Material Model ",
    logo: Time,
    desc: "The Time and Material model provides flexibility for projects with evolving scopes. Clients purchase hours for efficient resource allocation over a defined timeframe, making it cost-efficient and adaptable for projects within larger applications.",
  },
  {
    name: "Retainer Model",
    logo: Retainer,
    desc: "The Retainer Model offers dedicated resources for a set contract duration, ensuring seamless communication and a dependable resource pool. With an industry standard of 160 hours per month, it fosters flexibility for ongoing projects and sustained collaboration.",
  },
];
const EngagementModel = () => {
  return (
    <Row className="engagement_model">
      <Col sm={24} xl={24}>
        <Row justify={"center"} align={"middle"}>
          <Col sm={24} xl={16}>
            <h1 className="title">
              Our <span>Engagement Model</span>
            </h1>
            <p className="desc">
              Our core values are the pillars that define our identity and guide
              our actions. These values underpin ZenIntel commitment to
              excellence, client success, and industry leadership.
            </p>
          </Col>
          <Col span={24} className="feature_container">
            {MODELS?.map((model, index) => (
              <div className="container" key={index}>
                <div className="image_container">
                  <img src={model.logo} alt={model.name} />
                  <h3 className="name">{model.name}</h3>
                  <span className="desc">{model.desc}</span>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EngagementModel;

import React from "react";
import { Col, Row } from "antd";

const OurApproach = () => {
  return (
    <Row className="our_approach">
      <Col span={24}>
        <Row className="web">
          <Col xl={11} className="left_area">
            <div style={{ minHeight: 220 }}>
              <span className="title">Our Approach</span>
              <span className="sub_title">Take a look at how we work</span>
              <p className="description">
                This reflects our dedication to client triumph, encouraging
                transparency, and enabling adaptability in addressing evolving
                project needs.
              </p>
            </div>

            <div className="text_content align_right">
              <div className="image_container">
                <span className="number">02</span>
              </div>
              <div className="content_container">
                <span className="title">Design & Prototype Development</span>
                <p className="description">
                  Craft UI/UX designs and generate interactive prototypes for
                  client evaluation.
                </p>
              </div>
            </div>

            <div className="empty_block"></div>

            <div className="text_content align_right">
              <div className="image_container">
                <span className="number">04</span>
              </div>
              <div className="content_container">
                <span className="title">Client Engagement</span>
                <p className="description">
                  Sustain open dialogue, share advancements, and integrate
                  client input.
                </p>
              </div>
            </div>
          </Col>
          <Col xl={2} className="middle_area">
            <div className="line" />
          </Col>
          <Col xl={11} className="right_area">
            <div className="text_content align_left">
              <div className="image_container">
                <div className="box"></div>
                <span className="number">01</span>
              </div>
              <div className="content_container">
                <span className="title">Discovery & Strategic Planning </span>
                <p className="description">
                  Comprehend client requirements, establish goals, and delineate
                  project scope according to chosen engagement models.
                </p>
              </div>
            </div>

            <div className="empty_block"></div>

            <div className="text_content align_left">
              <div className="image_container">
                <div className="box"></div>
                <span className="number">03</span>
              </div>
              <div className="content_container">
                <span className="title">Development & Quality Assurance</span>
                <p className="description">
                  Implement solutions using state-of-the-art technologies,
                  backed by meticulous testing.
                </p>
              </div>
            </div>

            <div className="empty_block"></div>

            <div className="text_content align_left">
              <div className="image_container">
                <div className="box"></div>
                <span className="number">05</span>
              </div>
              <div className="content_container">
                <span className="title">Deployment & Assistance</span>
                <p className="description">
                  Ensure smooth deployment, oversee performance, and deliver
                  ongoing support for enduring success.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mobile">
          <Col sm={24} className="left_area">
            <div style={{ minHeight: 220 }}>
              <span className="title">Our Approach</span>
              <span className="sub_title">Take a look at how we work</span>
              <p className="description">
                This reflects our dedication to client triumph, encouraging
                transparency, and enabling adaptability in addressing evolving
                project needs.
              </p>
            </div>
          </Col>
          <Col sm={24} className="right_area">
            <div className="text_content align_left">
              <div className="image_container">
                <div className="box"></div>
                <span className="number">01</span>
              </div>
              <div className="content_container">
                <span className="title">Discovery & Strategic Planning </span>
                <p className="description">
                  Comprehend client requirements, establish goals, and delineate
                  project scope according to chosen engagement models.
                </p>
              </div>
            </div>

            <div className="text_content align_right">
              <div className="image_container">
                <span className="number">02</span>
              </div>
              <div className="content_container">
                <span className="title">Design & Prototype Development</span>
                <p className="description">
                  Craft UI/UX designs and generate interactive prototypes for
                  client evaluation.
                </p>
              </div>
            </div>

            <div className="text_content align_left">
              <div className="image_container">
                <div className="box"></div>
                <span className="number">03</span>
              </div>
              <div className="content_container">
                <span className="title">Development & Quality Assurance</span>
                <p className="description">
                  Implement solutions using state-of-the-art technologies,
                  backed by meticulous testing.
                </p>
              </div>
            </div>

            <div className="text_content align_right">
              <div className="image_container">
                <span className="number">04</span>
              </div>
              <div className="content_container">
                <span className="title">Client Engagement</span>
                <p className="description">
                  Sustain open dialogue, share advancements, and integrate
                  client input.
                </p>
              </div>
            </div>

            <div className="text_content align_left">
              <div className="image_container">
                <div className="box"></div>
                <span className="number">05</span>
              </div>
              <div className="content_container">
                <span className="title">Deployment & Assistance</span>
                <p className="description">
                  Ensure smooth deployment, oversee performance, and deliver
                  ongoing support for enduring success.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <p className="footer_text">
          Backed by a strong technological framework and a wide range of
          services,<span> ZenIntel</span> is committed to driving businesses
          forward in the digital age. Partner with us for unmatched expertise
          and innovation.
        </p>
      </Col>
    </Row>
  );
};

export default OurApproach;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import Collapsible from "react-collapsible";
import { Button } from "./Button";
import Dropdown, { MenuList1, MenuList2, MenuList3 } from "./Dropdown";
import DeskTopNav from "../../assets/svgs/DesktopNavBar.svg";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [activeSubMenuItem, setSubActiveMenuItem] = useState("");

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setDropdown(false);
    setClick(false);
  };

  useEffect(() => {
    activeSubMenuItem && setActiveMenuItem("services");
  }, [activeSubMenuItem]);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      // setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      // setDropdown(false);
    }
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    closeMobileMenu();
  };

  return (
    <>
      <nav className="navbar">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          {/* <img src={DeskTopNav} alt={"DeskTopNav"} /> */}
          {/* <i className="fab fa-firstdraft" /> */}
          <p>ZENINTEL</p>
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className={`nav-links ${
                activeMenuItem === "home" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("home")}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about"
              className={`nav-links ${
                activeMenuItem === "about" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("about")}
            >
              About us
            </Link>
          </li>
          <li
            className="nav-item"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              className={`nav-links desktop-only ${
                activeMenuItem === "services" ? "active" : ""
              }`}
            >
              Services <i className="fas fa-caret-down" />
            </Link>
            <Link className="nav-links mobile-only">
              <div className="nav-collapse">
                <Collapsible
                  trigger={["Services", <BsChevronDown />]}
                  accordionPosition
                >
                  {[...MenuList1, ...MenuList2, ...MenuList3].map(
                    (item, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className={"dropdown-link"}
                            to={`services${item.path}`}
                            onClick={() => handleMenuItemClick("about")}
                          >
                            {item.title}
                          </Link>
                        </li>
                      );
                    }
                  )}
                </Collapsible>
              </div>
            </Link>
            {dropdown && (
              <Dropdown
                {...{
                  activeSubMenuItem,
                  setSubActiveMenuItem,
                  closeMenu: () => {
                    onMouseLeave();
                    closeMobileMenu();
                  },
                }}
              />
            )}
          </li>
          <li className="nav-item">
            <Link
              to="/technology"
              className={`nav-links ${
                activeMenuItem === "technology" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("technology")}
            >
              Technology
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/blogs"
              className={`nav-links ${
                activeMenuItem === "blogs" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("blogs")}
            >
              Blogs
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/join-with-us"
              className={`nav-links nav-links-mobile ${
                activeMenuItem === "join" ? "active" : ""
              }`}
              style={{
                color: "black",
              }}
              onClick={() => handleMenuItemClick("join")}
            >
              Join Our Team
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact-us"
              className={`nav-links nav-links-mobile ${
                activeMenuItem === "connect" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("connect")}
            >
              Connect with us
            </Link>
          </li>
        </ul>
        <Button />
      </nav>
    </>
  );
}

export default Navbar;

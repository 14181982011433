import React, { useRef, useState } from "react";
import { Col, Row, Spin } from "antd";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import Mobile from "assets/svgs/contact-us/mobile.svg";
import Email from "assets/svgs/contact-us/mail.svg";
import Location from "assets/svgs/contact-us/location.svg";
import Globe from "assets/svgs/contact-us/globe.svg";
import Sqare from "assets/svgs/sqaure.svg";
import SqareCheck from "assets/svgs/square-check.svg";
import Button from "components/button";
import { showToast } from "helpers";
import { NOTIFICATION_STATUS_TYPES } from "constants/app-constant";
import { API_URL } from "constants/api-constants";
import Footer from "components/footer";
import "./join-our-team.scss";

const LIST = [
  {
    label: "+91 7401504726",
    icon: Mobile,
  },
  {
    label: "sales@zen-intel.com",
    icon: Email,
  },
  {
    label: "https://zen-intel.com",
    icon: Globe,
  },
  {
    label: "Chennai, Tamil Nadu",
    icon: Location,
  },
];

const JOB_TYPE = [
  {
    label: "Full - Time",
    value: "Full - Time",
  },
  {
    label: "Part - Time",
    value: "Part - Time",
  },
];

export const Input = ({
  label,
  type = "text",
  placeholder,
  value,
  onChange,
  name,
  ...rest
}) => {
  return (
    <div className="input">
      <label>{label}</label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        {...{
          ...(placeholder && {
            placeholder,
          }),
        }}
        {...rest}
      />
    </div>
  );
};

const DEFAULT_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  jobTitle: "",
  totalExperience: "",
  linkedInProfile: "",
  employmentType: [],
  resume: null,
};

const JoinOurTeam = () => {
  const [details, setDetails] = useState(DEFAULT_STATE);
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "employmentType") {
      setDetails((prevDetails) => {
        const updatedService = prevDetails.employmentType.includes(value)
          ? prevDetails.employmentType.filter((item) => item !== value)
          : [...prevDetails.employmentType, value];
        return {
          ...prevDetails,
          employmentType: updatedService,
        };
      });
    } else {
      setDetails({
        ...details,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (
      !details.firstName ||
      !details.lastName ||
      !details.email ||
      !details.phone ||
      !details.jobTitle ||
      !details.totalExperience ||
      !details.linkedInProfile ||
      !details.employmentType.length
    ) {
      showToast(
        "Error",
        "Please fill in all fields",
        NOTIFICATION_STATUS_TYPES.ERROR
      );
      setLoading(false);
    } else {
      details.employmentType = details?.employmentType?.join(",");

      const formData = new FormData();
      formData.append("firstName", details.firstName);
      formData.append("lastName", details.lastName);
      formData.append("email", details.email);
      formData.append("phone", details.phone);
      formData.append("jobTitle", details.jobTitle);
      formData.append("totalExperience", details.totalExperience);
      formData.append("linkedInProfile", details.linkedInProfile);
      formData.append("employmentType", details.employmentType);
      formData.append("resume", details.resume);

      axios
        .post(API_URL.JOIN_US, formData)
        .then((res) => {
          showToast(
            "Success",
            `Thank you for reaching out to us, we're eager to collaborate with you`
          );
          setLoading(false);
          setDetails(DEFAULT_STATE);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setDetails(DEFAULT_STATE);
        });
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <Row
      className="join_our_team"
      style={{
        paddingBottom: 0,
      }}
    >
      <Col sm={24} xl={24}>
        <Row
          justify={"center"}
          align={"middle"}
          gutter={[20, 20]}
          style={{
            margin: 0,
          }}
        >
          <Col sm={24} xl={16}>
            <h1 className="title">
              Join <span>our team</span>
            </h1>
            <p className="desc">
              Do you feel your coding skills are underutilized? Why wait? Sign
              up now. Join ZenIntel, one of the top-skilled communities in India
            </p>
          </Col>
          <Col xl={20} sm={24}>
            <Row gutter={[20, 20]} className="input_area">
              <Col xl={9} className="left_area">
                <div className="input_container">
                  <span className="contact_info">Contact Information</span>
                  <p className="contact_desc">
                    Fill up the form and our Team will get back to you within 24
                    hours.
                  </p>
                  <ul className="links">
                    {LIST.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="icon_container">
                            <img src={item.icon} alt="icon" />
                          </div>
                          <span>{item.label}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
              <Col sm={24} xl={15} className="right_area">
                {loading ? (
                  <div className="loader">
                    <Spin
                      spinning={loading}
                      tip="Loading..."
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 40, color: "#3f4ff8" }}
                          spin
                        />
                      }
                    />
                  </div>
                ) : (
                  <Row className="text_input_area" gutter={[20, 40]}>
                    <Col sm={24} xl={12} className="input_area">
                      <Input
                        label="First Name"
                        value={details.firstName}
                        name="firstName"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col sm={24} xl={12} className="input_area">
                      <Input
                        label="Last Name"
                        value={details.lastName}
                        name="lastName"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col sm={24} xl={12} className="input_area">
                      <Input
                        label="Email id"
                        value={details.email}
                        name="email"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col sm={24} xl={12} className="input_area">
                      <Input
                        label="Phone"
                        value={details.phone}
                        name="phone"
                        onChange={handleInputChange}
                        maxLength={10}
                      />
                    </Col>
                    <Col sm={24} xl={12} className="input_area">
                      <Input
                        label="Job Title"
                        value={details.jobTitle}
                        name="jobTitle"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col sm={24} xl={12} className="input_area">
                      <Input
                        label="Total years of experience"
                        value={details.totalExperience}
                        name="totalExperience"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col sm={24} xl={24} className="input_area">
                      <Input
                        label="LinkedIn profile link"
                        value={details.linkedInProfile}
                        name="linkedInProfile"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col xl={12} sm={24} className="what_service">
                      <label className="label_style">Upload Resume</label>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        accept=".pdf, .doc, .docx"
                        onChange={(e) => {
                          setDetails({ ...details, resume: e.target.files[0] });
                        }}
                      />
                      <button className="upload_button" onClick={onButtonClick}>
                        Browse Files
                      </button>
                      {details?.resume?.name && (
                        <span className="file_name">
                          {details?.resume?.name}
                        </span>
                      )}
                      <span className="allowed_type">
                        Accepted file types: PDF, DOC, DOCX
                      </span>
                    </Col>
                    <Col xl={12} sm={24} className="what_service">
                      <ul className="service_selection">
                        {JOB_TYPE.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className={
                                details.employmentType.includes(item?.value)
                                  ? "selected"
                                  : ""
                              }
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: "employmentType",
                                    value: item?.value,
                                  },
                                })
                              }
                            >
                              <div className="check_container">
                                <img
                                  src={
                                    details.employmentType.includes(item?.value)
                                      ? SqareCheck
                                      : Sqare
                                  }
                                  alt="icon"
                                />
                                <label htmlFor={item?.value}>
                                  {item?.label}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                    <Col span={24} className="button_container">
                      <Button onClick={handleSubmit}>Submit</Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        style={{
          paddingTop: 20,
        }}
      >
        <Footer />
      </Col>
    </Row>
  );
};

export default JoinOurTeam;
